.DetailContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.DetailHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 12px;

  border-bottom: 1px solid var(--dop-color-border-basic-level1, #d8d8d8);
}

.DetailTitle {
  @apply text-lg font-medium;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  height: 36px;

  display: flex;
  align-items: center;
  justify-content: start !important;
  gap: 8px;
}

.DetailNotificationDate {
  @apply text-2xs font-normal;
  color: var(--dop-color-text-basic-level3, #7f8082);
}

.NotificationContent {
  @apply text-sm font-normal;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  margin-top: 28px;
  margin-bottom: 32px;
}

.FileAndLink {
  display: flex;
  flex-direction: column;
  gap: 40px;

  margin-bottom: 20px;
}

.FileContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.Link {
  @apply text-2xs font-normal;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  align-self: flex-start;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 26px;
  width: fit-content;

  padding: 0 8px;

  border-radius: 26px;
  border: 1px solid var(--dop-color-border-basic-level2, #cfd1d4);
  background-color: var(--dop-color-bg-basic-base, #ffffff);

  &:hover {
    border-color: var(--dop-color-border-basic-level2-hover, #a3a6aa);
    background-color: var(--dop-color-bg-basic-base-hover, #f2f2f2);
  }
}

.ForwardBackwardButton {
  display: flex;
  align-items: center;
  gap: 16px !important;

  height: 48px;

  padding: 0 4px;

  border-top: 1px solid var(--dop-color-border-basic-level1, #eaecef);
}

.ForwardBackwardTitle {
  flex-shrink: 0;

  @apply text-sm font-semibold;
  color: var(--dop-color-text-basic-level3, #9b9c9e);

  overflow: hidden;
  text-overflow: ellipsis;
}

.ForwardBackwardPreviewTitle {
  @apply text-sm font-semibold;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  max-width: 420px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.ForwardBackwardChevron {
  flex-shrink: 0;

  color: var(--dop-color-icon-basic-level3, #9b9c9e);
}

.ListButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 640px;

  padding: 12px 0;
}

.AlertContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  height: 560px;
}

.AlertMessage {
  @apply text-sm font-medium;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}
